var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-row',{attrs:{"no-gutters":""}},[_c('base-col',{attrs:{"cols":"12"}},[_c('base-container',{attrs:{"vertical-center":""}},[(_vm.needCurrentUsingTime)?_c('base-text',[_vm._v(" Fairgrit 導入時 ")]):_c('div',{staticClass:"mr-2 pb-1"},[_c('date-picker-datetime',{attrs:{"outlined":"","hide-details":"","dense":"","inline":"","only-future":"","frozen":_vm.frozen,"width":"10em"},on:{"input":_vm.onChange},model:{value:(_vm.approvalFlow.begin_datetime),callback:function ($$v) {_vm.$set(_vm.approvalFlow, "begin_datetime", $$v)},expression:"approvalFlow.begin_datetime"}})],1),(_vm.frozen)?_c('base-text',[_vm._v(" から有効 ")]):[_c('base-text',[_vm._v(" からこの設定を有効にする ")]),_c('base-hint-marker',[_vm._v(" すぐに設定を有効にしたい場合は本日の日付を選択してください。 ")])]],2)],1)],1),_c('base-divider'),_c('base-row',[_c('base-col',{attrs:{"cols":"6"}},[_c('base-text-field',{attrs:{"label":"承認フロー名","label-cols":"2","frozen":_vm.frozen},on:{"input":_vm.onChange},model:{value:(_vm.approvalFlow.title),callback:function ($$v) {_vm.$set(_vm.approvalFlow, "title", $$v)},expression:"approvalFlow.title"}})],1)],1),_c('base-item-box',[_c('base-row',{staticClass:"py-6"},[_c('base-col',{attrs:{"cols":"1"}}),_vm._v(" "),_c('base-col',{attrs:{"cols":"1"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-label',{attrs:{"text":"申請","block":""}})],1)],1),_vm._l((_vm.approvalFlow.approval_phase_list),function(item,index){return [_c('base-col',{key:index,attrs:{"cols":"1"}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('div',{staticStyle:{"position":"relative"}},[_c('base-icon',{attrs:{"icon":"fas fa-arrow-right","large":""}}),(!_vm.frozen)?_c('div',{class:{
                  'add-phase-icon': true,
                  'primary': !_vm.disabledAdd,
                   'wont': _vm.disabledAdd,
                }},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('base-button',{attrs:{"icon":"far fa-plus","small":"","color":"white","disabled":_vm.disabledAdd,"disabled-message":_vm.disabledMessage},on:{"click":function($event){return _vm.add(index)}}})],1)],1):_vm._e()],1)])],1),_c('base-col',{key:(index + "_card"),attrs:{"cols":"2"}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('base-card',{directives:[{name:"ripple",rawName:"v-ripple",value:(!_vm.frozen),expression:"!frozen"}],style:(_vm.styles),on:{"click":function($event){return _vm.mod(index)}}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":"","direction-column":""}},[(item.only_same_department)?_c('base-label',{staticClass:"mb-2",attrs:{"text":"同一所属・部門","small":""}}):_vm._e(),_vm._l((item.role_id_list),function(roleId,index){return _c('base-typography',{key:roleId,attrs:{"bold":"","last":item.role_id_list.length === index,"value":_vm.getRoleName(roleId)}})}),_c('approver-list-popup',{attrs:{"role-id-list":item.role_id_list,"only-same-department":item.only_same_department}})],2),(!_vm.frozen)?_c('base-button',{staticClass:"del-phase-button",attrs:{"icon":"fas fa-times","small":"","color":"wont"},on:{"click":function($event){return _vm.delPhase(index)}}}):_vm._e()],1)],1)],1),(index === 2)?_c('base-col',{key:(index + "_blank"),attrs:{"cols":"1"}}):_vm._e()]}),_c('base-col',{attrs:{"cols":"1"}},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('div',{staticStyle:{"position":"relative"}},[_c('base-icon',{attrs:{"icon":"fas fa-arrow-right","large":""}}),(!_vm.frozen)?_c('div',{class:{
                  'add-phase-icon': true,
                  'primary': !_vm.disabledAdd,
                   'wont': _vm.disabledAdd,
                }},[_c('base-container',{attrs:{"vertical-center":"","horizontal-center":""}},[_c('base-button',{attrs:{"icon":"far fa-plus","small":"","color":"white","disabled":_vm.disabledAdd,"disabled-message":_vm.disabledMessage},on:{"click":function($event){return _vm.add(_vm.approvalFlow.approval_phase_list.length)}}})],1)],1):_vm._e()],1)])],1),_c('base-col',{attrs:{"cols":"1"}},[_c('base-container',{attrs:{"vertical-center":""}},[_c('base-label',{attrs:{"text":"完了","block":"","color":"success"}})],1)],1)],2)],1),(!_vm.frozen)?_c('base-modal',{attrs:{"confirm-text":"追加","confirm-disabled":!_vm.editApprovalPhase.role_id_list.length,"width":"500","persistent":""},on:{"confirm":function($event){_vm.isAdd ? _vm.addPhase() : _vm.modPhase()}},model:{value:(_vm.openModal),callback:function ($$v) {_vm.openModal=$$v},expression:"openModal"}},[_c('role-multiple-select',{attrs:{"label":"決裁可能な役職","role-type":1},model:{value:(_vm.editApprovalPhase.role_id_list),callback:function ($$v) {_vm.$set(_vm.editApprovalPhase, "role_id_list", $$v)},expression:"editApprovalPhase.role_id_list"}}),_c('base-container',{attrs:{"vertical-center":"","horizontal-end":""}},[_c('base-check-box',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('base-typography',{staticClass:"mt-1",attrs:{"value":"申請者と同じ所属・部門のみ決裁可能","caption":"","single":""}})]},proxy:true}],null,false,1571823603),model:{value:(_vm.editApprovalPhase.only_same_department),callback:function ($$v) {_vm.$set(_vm.editApprovalPhase, "only_same_department", $$v)},expression:"editApprovalPhase.only_same_department"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }